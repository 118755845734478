<template>
 <div id="statistik">

    <div id="logo">
       <div class="container">
          <a href="https://www.apollo.de" target="_blank" name="Apollo Website" style="text-decoration: underline; outline: none; color: #fff;">
             <svg class="c-logo__image" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="160.9" height="33.2" viewBox="-201.7 403.9 160.9 33.2" xml:space="preserve">
                <g>
                   <g transform="translate(-117 -41)">
                      <g transform="translate(117 41)">
                         <path class="st0" style="fill:#054f9d" d="M-84.6 410c0 .9-.8 1.7-1.7 1.7-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7 1.7.8 1.7 1.7"></path>
                         <path class="st0" style="fill:#054f9d" d="M-90.1 412.5c0 .8-.6 1.4-1.4 1.4s-1.4-.6-1.4-1.4c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4"></path>
                         <path class="st0" style="fill:#054f9d" d="M-69.7 410c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-67.1 405.7c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-78.2 420.5c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-73 406.3c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-78.8 407.8c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8c1 .1 1.8.9 1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-74.9 411.4c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-77.8 414.5c.8.6.9 1.8.3 2.5-.6.8-1.8.9-2.6.3s-.9-1.8-.3-2.5c.6-.8 1.8-.9 2.6-.3"></path>
                         <path class="st0" style="fill:#054f9d" d="M-80.5 412.5c0 .9-.8 1.7-1.7 1.7-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7c1 0 1.7.7 1.7 1.7"></path>
                         <path class="st0" style="fill:#054f9d" d="M-82.2 416.6c0 .9-.7 1.6-1.6 1.6s-1.6-.7-1.6-1.6c0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6"></path>
                         <path class="st0" style="fill:#054f9d" d="M-83 420.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5c.9 0 1.5.6 1.5 1.5"></path>
                         <path class="st0" style="fill:#054f9d" d="M-90.7 416.7c0 .6-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2"></path>
                         <path class="st0" style="fill:#054f9d" d="M-94.8 415.6c0 .6-.5 1.1-1.1 1.1s-1.1-.5-1.1-1.1c0-.6.5-1.1 1.1-1.1.6.1 1.1.5 1.1 1.1"></path>
                         <path class="st0" style="fill:#054f9d" d="M-90.8 420.5c0 .6-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2"></path>
                         <path class="st0" style="fill:#054f9d" d="M-86.8 418.6c0 .7-.6 1.3-1.3 1.3-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3"></path>
                         <path class="st0" style="fill:#054f9d" d="M-86.1 414.6c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5 1.5.7 1.5 1.5"></path>
                         <path class="st0" style="fill:#054f9d" d="M-53.2 410c0 .9.8 1.7 1.7 1.7s1.7-.8 1.7-1.7-.8-1.7-1.7-1.7c-1 0-1.7.8-1.7 1.7"></path>
                         <path class="st0" style="fill:#054f9d" d="M-47.8 412.5c0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4s-1.4.6-1.4 1.4"></path>
                         <path class="st0" style="fill:#054f9d" d="M-68.2 410c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-59.7 420.5c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-64.9 406.3c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8c-.9 0-1.8.8-1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-59.1 407.8c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8c-1 .1-1.8.9-1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-63 411.4c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-60 414.5c-.8.6-.9 1.8-.3 2.5.6.8 1.8.9 2.6.3s.9-1.8.3-2.5c-.7-.8-1.9-.9-2.6-.3"></path>
                         <path class="st0" style="fill:#054f9d" d="M-57.4 412.5c0 .9.8 1.7 1.7 1.7s1.7-.8 1.7-1.7-.8-1.7-1.7-1.7c-.9 0-1.7.7-1.7 1.7"></path>
                         <path class="st0" style="fill:#054f9d" d="M-55.7 416.6c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6-.9-.1-1.6.7-1.6 1.6"></path>
                         <path class="st0" style="fill:#054f9d" d="M-54.9 420.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.6-1.5 1.5"></path>
                         <path class="st0" style="fill:#054f9d" d="M-47.2 416.7c0 .6.5 1.2 1.2 1.2s1.2-.5 1.2-1.2c0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2"></path>
                         <path class="st0" style="fill:#054f9d" d="M-43 415.6c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1c0-.6-.5-1.1-1.1-1.1-.6.1-1.1.5-1.1 1.1"></path>
                         <path class="st0" style="fill:#054f9d" d="M-47.1 420.5c0 .6.5 1.2 1.2 1.2s1.2-.5 1.2-1.2c0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2"></path>
                         <path class="st0" style="fill:#054f9d" d="M-51 418.6c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3c0-.7-.6-1.3-1.3-1.3-.8 0-1.3.6-1.3 1.3"></path>
                         <path class="st0" style="fill:#054f9d" d="M-51.8 414.6c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.7-1.5 1.5"></path>
                         <path class="st0" style="fill:#054f9d" d="M-84.6 430.9c0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7 1.7-.8 1.7-1.7"></path>
                         <path class="st0" style="fill:#054f9d" d="M-90.1 428.4c0-.8-.6-1.4-1.4-1.4s-1.4.6-1.4 1.4.6 1.4 1.4 1.4 1.4-.6 1.4-1.4"></path>
                         <path class="st0" style="fill:#054f9d" d="M-67.1 435.3c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-73 434.6c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-78.8 433.1c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-77.8 426.4c.8-.6.9-1.8.3-2.5-.6-.8-1.8-.9-2.6-.3-.8.6-.9 1.8-.3 2.5.6.8 1.8 1 2.6.3"></path>
                         <path class="st0" style="fill:#054f9d" d="M-82.2 424.3c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6.7 1.6 1.6 1.6 1.6-.6 1.6-1.6"></path>
                         <path class="st0" style="fill:#054f9d" d="M-94.8 425.3c0-.6-.5-1.1-1.1-1.1s-1.1.5-1.1 1.1.5 1.1 1.1 1.1 1.1-.5 1.1-1.1"></path>
                         <path class="st0" style="fill:#054f9d" d="M-86.8 422.4c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3c.7-.1 1.3-.6 1.3-1.3"></path>
                         <path class="st0" style="fill:#054f9d" d="M-53.2 430.9c0-.9.8-1.7 1.7-1.7s1.7.8 1.7 1.7-.8 1.7-1.7 1.7c-1 0-1.7-.8-1.7-1.7"></path>
                         <path class="st0" style="fill:#054f9d" d="M-47.8 428.4c0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4s-.6 1.4-1.4 1.4-1.4-.6-1.4-1.4"></path>
                         <path class="st0" style="fill:#054f9d" d="M-64.9 434.6c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8c-.9 0-1.8-.8-1.8-1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-59.1 433.1c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8"></path>
                         <path class="st0" style="fill:#054f9d" d="M-60 426.4c-.8-.6-.9-1.8-.3-2.5.6-.8 1.8-.9 2.6-.3.8.6.9 1.8.3 2.5-.7.8-1.9 1-2.6.3"></path>
                         <path class="st0" style="fill:#054f9d" d="M-55.7 424.3c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6c-.9.1-1.6-.6-1.6-1.6"></path>
                         <path class="st0" style="fill:#054f9d" d="M-43 425.3c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1-.5 1.1-1.1 1.1-1.1-.5-1.1-1.1"></path>
                         <path class="st0" style="fill:#054f9d" d="M-51 422.4c0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3-.6 1.3-1.3 1.3c-.8-.1-1.3-.6-1.3-1.3"></path>
                         <path class="st0" style="fill:#054f9d" d="M-94.8 420.5c0 .6-.5 1.1-1.1 1.1s-1.1-.5-1.1-1.1.5-1.1 1.1-1.1 1.1.5 1.1 1.1"></path>
                         <path class="st0" style="fill:#054f9d" d="M-43 420.5c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1-.5-1.1-1.1-1.1-1.1.5-1.1 1.1"></path>
                         <path class="st0" style="fill:#054f9d" d="M-153.1 429.1c-6.8 0-7.7-3.8-7.7-8.6s.9-8.6 7.7-8.6 7.6 3.8 7.6 8.6c.1 4.7-.7 8.6-7.6 8.6m0-14.7c-3.8 0-4 2.2-4 6.1 0 3.8.2 6 4 6s4-2.2 4-6c0-3.9-.2-6.1-4-6.1"></path>
                         <path class="st0" style="fill:#054f9d" d="M-135.7 428.1c0 .2-.1.4-.3.5-.3.2-1.2.4-2.6.4-2.5 0-3.9-1.3-3.9-3.7v-19.1c0-.3.2-.5.5-.5l2.6-.5h.1c.3 0 .4.2.4.5V425c0 1.1.7 1.3 1.3 1.3.5 0 .8-.1 1.2-.1.2 0 .3 0 .3.3l.4 1.6z"></path>
                         <path class="st0" style="fill:#054f9d" d="M-126.6 428.1c0 .2-.1.4-.3.5-.3.2-1.2.4-2.6.4-2.5 0-3.9-1.3-3.9-3.7v-19.1c0-.3.2-.5.5-.5l2.6-.5h.1c.3 0 .4.2.4.5V425c0 1.1.7 1.3 1.3 1.3.5 0 .8-.1 1.2-.1.2 0 .3 0 .3.3l.4 1.6z"></path>
                         <path class="st0" style="fill:#054f9d" d="M-117.9 429.1c-6.8 0-7.7-3.8-7.7-8.6s.9-8.6 7.7-8.6 7.6 3.8 7.6 8.6c.1 4.7-.8 8.6-7.6 8.6m0-14.7c-3.8 0-4 2.2-4 6.1 0 3.8.2 6 4 6s4-2.2 4-6c0-3.9-.2-6.1-4-6.1"></path>
                         <path class="st0" style="fill:#054f9d" d="M-180.4 428.3c0 .3-.2.3-.4.3h-3.1c-.2 0-.5-.2-.5-.4l-2.9-8.7h-7.6l-2.9 8.7c-.1.2-.3.3-.5.3h-3c-.3 0-.4-.1-.4-.4v-.2l7.7-22.1c.1-.2.4-.4.6-.4h4.7c.2 0 .5.2.6.4l7.6 22.1c.1.3.1.4.1.4zm-10.5-19.9h-.4l-2.7 8.3h5.9l-2.8-8.3z"></path>
                         <path class="st0" style="fill:#054f9d" d="M-170 411.9c-2.1 0-4.5.4-6.3 1-1.5.5-1.9 1-1.9 2.5v19.4c0 .3.2.5.6.5l2.5-.5c.3-.1.5-.2.5-.6v-5.6c1.1.3 2.6.5 4.2.5 5.8 0 7.1-3.2 7.1-8.6 0-5.1-.8-8.6-6.7-8.6m-1.1 14.6c-1.8 0-3.2-.3-3.5-.4v-11c.4-.1 2.4-.6 3.8-.6 2.6 0 3.9.6 3.9 6 0 4.8-1 6-4.2 6"></path>
                         <path class="st1" style="fill:#e9531e" d="M-67.4 413.6c0 .8-.7 1.5-1.5 1.5-.9 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.6 1.5 1.5"></path>
                         <path class="st1" style="fill:#e9531e" d="M-71.9 414c.5.7.3 1.6-.3 2.1-.7.5-1.7.3-2.2-.3-.5-.7-.3-1.6.3-2.1.8-.5 1.7-.4 2.2.3"></path>
                         <path class="st1" style="fill:#e9531e" d="M-75.2 417c.8.3 1.3 1.1 1 1.9s-1.1 1.2-1.9 1c-.8-.3-1.3-1.1-1-1.9.2-.8 1.1-1.3 1.9-1"></path>
                         <path class="st1" style="fill:#e9531e" d="M-66 414c-.5.7-.3 1.6.3 2.1s1.7.3 2.2-.3.3-1.6-.3-2.1c-.8-.5-1.7-.4-2.2.3"></path>
                         <path class="st1" style="fill:#e9531e" d="M-62.7 417c-.8.3-1.3 1.1-1 1.9s1.1 1.2 1.9 1c.8-.3 1.3-1.1 1-1.9-.2-.8-1.1-1.3-1.9-1"></path>
                         <path class="st1" style="fill:#e9531e" d="M-69.7 431c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                         <path class="st1" style="fill:#e9531e" d="M-74.9 429.5c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                         <path class="st1" style="fill:#e9531e" d="M-80.5 428.4c0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7c1 0 1.7-.7 1.7-1.7"></path>
                         <path class="st1" style="fill:#e9531e" d="M-90.7 424.3c0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2.7-.1 1.2-.6 1.2-1.2"></path>
                         <path class="st1" style="fill:#e9531e" d="M-86.1 426.4c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5.7 1.5 1.5 1.5c.8-.1 1.5-.7 1.5-1.5"></path>
                         <path class="st1" style="fill:#e9531e" d="M-67.4 427.3c0-.8-.7-1.5-1.5-1.5-.9 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.8.1 1.5-.6 1.5-1.5"></path>
                         <path class="st1" style="fill:#e9531e" d="M-71.9 426.9c.5-.7.3-1.6-.3-2.1-.7-.5-1.7-.3-2.2.3-.5.7-.3 1.6.3 2.1.8.5 1.7.4 2.2-.3"></path>
                         <path class="st1" style="fill:#e9531e" d="M-75.2 423.9c.8-.3 1.3-1.1 1-1.9-.3-.8-1.1-1.2-1.9-1-.8.3-1.3 1.1-1 1.9.2.8 1.1 1.3 1.9 1"></path>
                         <path class="st1" style="fill:#e9531e" d="M-68.2 431c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8"></path>
                         <path class="st1" style="fill:#e9531e" d="M-63 429.5c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8"></path>
                         <path class="st1" style="fill:#e9531e" d="M-57.4 428.4c0-.9.8-1.7 1.7-1.7s1.7.8 1.7 1.7-.8 1.7-1.7 1.7c-.9 0-1.7-.7-1.7-1.7"></path>
                         <path class="st1" style="fill:#e9531e" d="M-47.2 424.3c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2c0 .6-.5 1.2-1.2 1.2-.7-.1-1.2-.6-1.2-1.2"></path>
                         <path class="st1" style="fill:#e9531e" d="M-51.8 426.4c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5c-.8-.1-1.5-.7-1.5-1.5"></path>
                         <path class="st1" style="fill:#e9531e" d="M-66 426.9c-.5-.7-.3-1.6.3-2.1.7-.5 1.7-.3 2.2.3.5.7.3 1.6-.3 2.1-.8.5-1.7.4-2.2-.3"></path>
                         <path class="st1" style="fill:#e9531e" d="M-62.7 423.9c-.8-.3-1.3-1.1-1-1.9.3-.8 1.1-1.2 1.9-1 .8.3 1.3 1.1 1 1.9-.2.8-1.1 1.3-1.9 1"></path>
                      </g>
                   </g>
                </g>
             </svg>
          </a>
       </div>
   
    </div>
    <div id="header">
       <div class="container">
                         <div class="col-100"><div class="col-50"> 
                           
           </div><div class="col-50" style="text-align: center;"> 
                           <h1 >Statistik // Muttertag 2021</h1>
           </div>
                           <div class="col-100">
   
               <div class="col-50"> 
                      
<button class="btn" @click="reload"><span :class="{ 'spinner': loading }">&nbsp;</span><span>Liste aktualisieren</span></button>
               </div>                          <div class="col-50"> 
<input :type="inputtype" ref="pwinput" v-model="kennwort" placeholder="Bitte Kennwort eingeben" @change="reload" />
               </div></div>
                         </div>
       </div>
    </div>
    <div id="stats" v-if="error == ''">
       <div class="container">
           <h2>ANZAHL AUFRUFE</h2>
         <div class="col-100">
            <div class="spaltenname">Gesamt</div>
            <div class="wert">{{ stats.aufrufe_v1_ow + stats.aufrufe_v1_om + stats.aufrufe_v1_nw + stats.aufrufe_v1_nm + stats.aufrufe_v2_ow + stats.aufrufe_v2_om + stats.aufrufe_v2_nw + stats.aufrufe_v2_nm + stats.aufrufe_gast }}</div>
         </div>
         <div class="col-100"> 
            <div class="spaltenname">ohne Newsletter-Code</div>
            <div class="wert">{{ stats.aufrufe_gast }}</div>
         </div>
         <div class="col-100"> 
            <div class="aufrufe_name"></div>
            <div class="aufrufe_wert">Gesamt</div>
            <div class="aufrufe_wert">Version 1</div>
            <div class="aufrufe_wert">Version 2</div>
         </div>
         <div class="col-100 gesamt"> 
            <div class="aufrufe_name">Alle</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_ow + stats.aufrufe_v1_om + stats.aufrufe_v1_nw + stats.aufrufe_v1_nm + stats.aufrufe_v2_ow + stats.aufrufe_v2_om + stats.aufrufe_v2_nw + stats.aufrufe_v2_nm }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_ow + stats.aufrufe_v1_om + stats.aufrufe_v1_nw + stats.aufrufe_v1_nm}}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v2_ow + stats.aufrufe_v2_om + stats.aufrufe_v2_nw + stats.aufrufe_v2_nm}}</div>
         </div>
         <div class="col-100"> 
            <div class="aufrufe_name">Weiblich</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_ow + stats.aufrufe_v1_nw + stats.aufrufe_v2_ow + stats.aufrufe_v2_nw }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_ow + stats.aufrufe_v1_nw }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v2_ow + stats.aufrufe_v2_nw }}</div>
         </div>
         <div class="col-100"> 
            <div class="aufrufe_name">Männlich</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_om + stats.aufrufe_v1_nm + stats.aufrufe_v2_om + stats.aufrufe_v2_nm }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_om + stats.aufrufe_v1_nm }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v2_om + stats.aufrufe_v2_nm }}</div>
         </div>
         <div class="col-100 gesamt"> 
            <div class="aufrufe_name">Öffner</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_ow + stats.aufrufe_v1_om + stats.aufrufe_v2_ow + stats.aufrufe_v2_om }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_ow + stats.aufrufe_v1_om }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v2_ow + stats.aufrufe_v2_om }}</div>
         </div>
         <div class="col-100"> 
            <div class="aufrufe_name">Weiblich</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_ow + stats.aufrufe_v2_ow }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_ow }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_ow }}</div>
         </div>
         <div class="col-100"> 
            <div class="aufrufe_name">Männlich</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_om + stats.aufrufe_v2_om }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_om }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v2_om }}</div>
         </div>
         <div class="col-100 gesamt"> 
            <div class="aufrufe_name">Nicht-Öffner</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_nm + stats.aufrufe_v1_nw + stats.aufrufe_v2_nm + stats.aufrufe_v2_nw  }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_nm + stats.aufrufe_v1_nw }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v2_nm + stats.aufrufe_v2_nw  }}</div>
         </div>
         <div class="col-100"> 
            <div class="aufrufe_name">Weiblich</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_nw + stats.aufrufe_v2_nw }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_nw }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v2_nw }}</div>
         </div>
         <div class="col-100"> 
            <div class="aufrufe_name">Männlich</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_nm + stats.aufrufe_v2_nm }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v1_nm }}</div>
            <div class="aufrufe_wert">{{ stats.aufrufe_v2_nm }}</div>
         </div>
    </div>
<br/>
<br/><br/>
<br/>
   <div class="container">
    
                  <h2>GESTALTETE POSTKARTEN NEWSLETTER V1 + V2</h2>
         <div class="col-100"> 
            <div class="postkarte_name"></div>
            <div class="postkarte_wert">Gesamt</div>
            <div class="postkarte_wert">Motiv 1</div>
            <div class="postkarte_wert">Motiv 2</div>
            <div class="postkarte_wert">Motiv 3</div>
         </div>
       
         <div class="col-100 gesamt">
              <div class="postkarte_name">GESAMT</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_ow_m2 + stats.v1_ow_m3 + stats.v1_nw_m1 + stats.v1_nw_m2 + stats.v1_nw_m3 + stats.v1_om_m1 + stats.v1_om_m2 + stats.v1_om_m3 + stats.v1_nm_m1 + stats.v1_nm_m2 + stats.v1_nm_m3 + stats.v2_ow_m1 + stats.v2_ow_m2 + stats.v2_ow_m3 + stats.v2_nw_m1 + stats.v2_nw_m2 + stats.v2_nw_m3 + stats.v2_om_m1 + stats.v2_om_m2 + stats.v2_om_m3 + stats.v2_nm_m1 + stats.v2_nm_m2 + stats.v2_nm_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_nw_m1 + stats.v1_om_m1 + stats.v1_nm_m1 + stats.v2_ow_m1 + stats.v2_nw_m1 + stats.v2_om_m1 + stats.v2_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m2 + stats.v1_nw_m2 + stats.v1_om_m2 + stats.v1_nm_m2 + stats.v2_ow_m2 + stats.v2_nw_m2 + stats.v2_om_m2 + stats.v2_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m3 + stats.v1_nw_m3 + stats.v1_om_m3 + stats.v1_nm_m3 + stats.v2_ow_m3 + stats.v2_nw_m3 + stats.v2_om_m3 + stats.v2_nm_m3 }}</div>
         </div> 
         <div class="col-100">
            <div class="postkarte_name">Weiblich</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_ow_m2 + stats.v1_ow_m3 + stats.v1_nw_m1 + stats.v1_nw_m2 + stats.v1_nw_m3 + stats.v2_ow_m1 + stats.v2_ow_m2 + stats.v2_ow_m3 + stats.v2_nw_m1 + stats.v2_nw_m2 + stats.v2_nw_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_nw_m1 + stats.v2_ow_m1 + stats.v2_nw_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m2 + stats.v1_nw_m2 + stats.v2_ow_m2 + stats.v2_nw_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m3 + stats.v1_nw_m3 + stats.v2_ow_m3 + stats.v2_nw_m3 }}</div>
         </div>
         <div class="col-100">
            <div class="postkarte_name">Männlich</div>
            <div class="postkarte_wert">{{ stats.v1_om_m1 + stats.v1_om_m2 + stats.v1_om_m3 + stats.v1_nm_m1 + stats.v1_nm_m2 + stats.v1_nm_m3 + stats.v2_om_m1 + stats.v2_om_m2 + stats.v2_om_m3 + stats.v2_nm_m1 + stats.v2_nm_m2 + stats.v2_nm_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_om_m1 + stats.v1_nm_m1 + stats.v2_om_m1 + stats.v2_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_om_m2 + stats.v1_nm_m2 + stats.v2_om_m2 + stats.v2_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_om_m3 + stats.v1_nm_m3 + stats.v2_om_m3 + stats.v2_nm_m3 }}</div>
         </div>
         <br/>
<br/><br/>
            <h2>GESTALTETE POSTKARTEN NEWSLETTER V1</h2>
         <div class="col-100"> 
            <div class="postkarte_name"></div>
            <div class="postkarte_wert">Gesamt</div>
            <div class="postkarte_wert">Motiv 1</div>
            <div class="postkarte_wert">Motiv 2</div>
            <div class="postkarte_wert">Motiv 3</div>
         </div>
       
         <div class="col-100 gesamt">
              <div class="postkarte_name">GESAMT</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_ow_m2 + stats.v1_ow_m3 + stats.v1_nw_m1 + stats.v1_nw_m2 + stats.v1_nw_m3 + stats.v1_om_m1 + stats.v1_om_m2 + stats.v1_om_m3 + stats.v1_nm_m1 + stats.v1_nm_m2 + stats.v1_nm_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_nw_m1 + stats.v1_om_m1 + stats.v1_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m2 + stats.v1_nw_m2 + stats.v1_om_m2 + stats.v1_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m3 + stats.v1_nw_m3 + stats.v1_om_m3 + stats.v1_nm_m3 }}</div>
         </div>
         <div class="col-100">
            <div class="postkarte_name">Weiblich</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_ow_m2 + stats.v1_ow_m3 + stats.v1_nw_m1 + stats.v1_nw_m2 + stats.v1_nw_m3  }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_nw_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m2 + stats.v1_nw_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m3 + stats.v1_nw_m3 }}</div>
         </div>
         <div class="col-100">
              <div class="postkarte_name">Männlich</div>
             <div class="postkarte_wert">{{ stats.v1_om_m1 + stats.v1_om_m2 + stats.v1_om_m3 + stats.v1_nm_m1 + stats.v1_nm_m2 + stats.v1_nm_m3  }}</div>
            <div class="postkarte_wert">{{ stats.v1_om_m1 + stats.v1_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_om_m2 + stats.v1_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_om_m3 + stats.v1_nm_m3 }}</div>
         </div>

         <div class="col-100 gesamt">
            <div class="postkarte_name">ÖFFNER</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_ow_m2 + stats.v1_ow_m3 + stats.v1_om_m1 + stats.v1_om_m2 + stats.v1_om_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_om_m1  }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m2 + stats.v1_om_m2  }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m3 + stats.v1_om_m3 }}</div>
         </div>
         <div class="col-100">
            <div class="postkarte_name">Weiblich</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 + stats.v1_ow_m2 + stats.v1_ow_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_ow_m3 }}</div>
         </div>
         <div class="col-100">
              <div class="postkarte_name">Männlich</div>
            <div class="postkarte_wert">{{ stats.v1_om_m1 + stats.v1_om_m2 + stats.v1_om_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_om_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_om_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_om_m3 }}</div>
         </div>
    
         <div class="col-100 gesamt">
              <div class="postkarte_name">NICHT-ÖFFNER</div>
            <div class="postkarte_wert">{{ stats.v1_nw_m1 + stats.v1_nw_m2 + stats.v1_nw_m3 + stats.v1_nm_m1 + stats.v1_nm_m2 + stats.v1_nm_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_nw_m1 + stats.v1_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_nw_m2 + stats.v1_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_nw_m3 + stats.v1_nm_m3 }}</div>
         </div>

         <div class="col-100">
            <div class="postkarte_name">Weiblich</div>
            <div class="postkarte_wert">{{ stats.v1_nw_m1 + stats.v1_nw_m2 + stats.v1_nw_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_nw_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_nw_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_nw_m1 }}</div>
         </div>
         <div class="col-100">
              <div class="postkarte_name">Männlich</div>
            <div class="postkarte_wert">{{ stats.v1_nm_m1 + stats.v1_nm_m2 + stats.v1_nm_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v1_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v1_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v1_nm_m3 }}</div>
         </div>
      </div>
<br/><br/><br/>
  <div class="container">
    
          
            <h2>GESTALTETE POSTKARTEN NEWSLETTER V2</h2>
         <div class="col-100"> 
            <div class="postkarte_name"></div>
            <div class="postkarte_wert">Gesamt</div>
            <div class="postkarte_wert">Motiv 1</div>
            <div class="postkarte_wert">Motiv 2</div>
            <div class="postkarte_wert">Motiv 3</div>
         </div>
       
         <div class="col-100 gesamt">
              <div class="postkarte_name">GESAMT</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m1 + stats.v2_ow_m2 + stats.v2_ow_m3 + stats.v2_nw_m1 + stats.v2_nw_m2 + stats.v2_nw_m3 + stats.v2_om_m1 + stats.v2_om_m2 + stats.v2_om_m3 + stats.v2_nm_m1 + stats.v2_nm_m2 + stats.v2_nm_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m1 + stats.v2_nw_m1 + stats.v2_om_m1 + stats.v2_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m2 + stats.v2_nw_m2 + stats.v2_om_m2 + stats.v2_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m3 + stats.v2_nw_m3 + stats.v2_om_m3 + stats.v2_nm_m3 }}</div>
         </div>
         <div class="col-100">
            <div class="postkarte_name">Weiblich</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m1 + stats.v2_ow_m2 + stats.v2_ow_m3 + stats.v2_nw_m1 + stats.v2_nw_m2 + stats.v2_nw_m3  }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m1 + stats.v2_nw_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m2 + stats.v2_nw_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m3 + stats.v2_nw_m3 }}</div>
         </div>
         <div class="col-100">
              <div class="postkarte_name">Männlich</div>
             <div class="postkarte_wert">{{ stats.v2_om_m1 + stats.v2_om_m2 + stats.v2_om_m3 + stats.v2_nm_m1 + stats.v2_nm_m2 + stats.v2_nm_m3  }}</div>
            <div class="postkarte_wert">{{ stats.v2_om_m1 + stats.v2_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v2_om_m2 + stats.v2_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v2_om_m3 + stats.v2_nm_m3 }}</div>
         </div>

         <div class="col-100 gesamt">
            <div class="postkarte_name">ÖFFNER</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m1 + stats.v2_ow_m2 + stats.v2_ow_m3 + stats.v2_om_m1 + stats.v2_om_m2 + stats.v2_om_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m1 + stats.v2_om_m1  }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m2 + stats.v2_om_m2  }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m3 + stats.v2_om_m3 }}</div>
         </div>
         <div class="col-100">
            <div class="postkarte_name">Weiblich</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m1 + stats.v2_ow_m2 + stats.v2_ow_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v2_ow_m3 }}</div>
         </div>
         <div class="col-100">
              <div class="postkarte_name">Männlich</div>
            <div class="postkarte_wert">{{ stats.v2_om_m1 + stats.v2_om_m2 + stats.v2_om_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v2_om_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v2_om_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v2_om_m3 }}</div>
         </div>
    
         <div class="col-100 gesamt">
              <div class="postkarte_name">NICHT-ÖFFNER</div>
            <div class="postkarte_wert">{{ stats.v2_nw_m1 + stats.v2_nw_m2 + stats.v2_nw_m3 + stats.v2_nm_m1 + stats.v2_nm_m2 + stats.v2_nm_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v2_nw_m1 + stats.v2_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v2_nw_m2 + stats.v2_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v2_nw_m3 + stats.v2_nm_m3 }}</div>
         </div>

         <div class="col-100">
            <div class="postkarte_name">Weiblich</div>
            <div class="postkarte_wert">{{ stats.v2_nw_m1 + stats.v2_nw_m2 + stats.v2_nw_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v2_nw_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v2_nw_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v2_nw_m1 }}</div>
         </div>
         <div class="col-100">
              <div class="postkarte_name">Männlich</div>
            <div class="postkarte_wert">{{ stats.v2_nm_m1 + stats.v2_nm_m2 + stats.v2_nm_m3 }}</div>
            <div class="postkarte_wert">{{ stats.v2_nm_m1 }}</div>
            <div class="postkarte_wert">{{ stats.v2_nm_m2 }}</div>
            <div class="postkarte_wert">{{ stats.v2_nm_m3 }}</div>
         </div>
      </div>
<br/><br/><br/>
   <div class="container">
    
           <h2>KLICKS</h2>
            
        <div class="col-100"> 
            <div class="spaltenname">Apollo-Logo</div>
            <div class="wert">{{ stats.clicks_logo }}</div>
         </div>
         <div class="col-100"> 
            <div class="spaltenname">CTA Newsletter</div>
            <div class="wert">{{ stats.clicks_newsletter }}</div>
         </div>
         <div class="col-100"> 
            <div class="spaltenname">CTA Apollo.de</div>
            <div class="wert">{{ stats.clicks_apollode }}</div>
         </div>
                <div class="col-100"> 
            <div class="spaltenname">CTA Filiale</div>
            <div class="wert">{{ stats.clicks_filiale }}</div>
         </div>
                <div class="col-100"> 
            <div class="spaltenname">Footer Aktionen</div>
            <div class="wert">{{ stats.clicks_aktionen }}</div>
         </div>


 </div>
  
</div>
   <div else-v-if>
      <div class="container">
         <div class="col-100" style="text-align: center;">
            <h2>{{ error }}</h2></div>      
         </div>
      </div>
</div>

</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
   components: {},
   data() {
     return {
         kennwort: '', 
         name: "",
         output: null,
         loading: false,
         error: 'Bitte Kennwort eingeben!',
         show: true,
         inputtype: 'text',
         stats: {}
      };
   },
   methods: {
      reload() {
               var vm = this;
         this.loading = true;
        const a =  axios.get( this.$_APOLLO_API_URL + '/muttertag/stats?p='+this.kennwort, {
               headers: {}
            }).then((response) => {
                  vm.stats = response.data.stats;
                  vm.error = response.data.error;
                  this.loading = false; 
            }).catch(err =>{
                this.error = err;
            this.loading = false; 
        });
      },
      togglePassword() {
            this.show = !this.show;
         if (this.show) this.inputtype = 'text'; else this.inputtype = 'password';
      }
   },
   computed: {
      aufrufe_gesamt() {
         return this.stats.aufrufe_ow + this.stats.aufrufe_nw + this.stats.aufrufe_nm + this.stats.aufrufe_om + this.stats.aufrufe_gast;
      },
      aufrufe_maennlich() {
         return this.stats.aufrufe_nm + this.stats.aufrufe_om;
      },
      aufrufe_weiblich() {
         return this.stats.aufrufe_ow + this.stats.aufrufe_nw;
      },
      aufrufe_newsletter() {
         return this.stats.aufrufe_ow + this.stats.aufrufe_nw + this.stats.aufrufe_nm + this.stats.aufrufe_om;
      },
      aufrufe_nichtoeffner() {
         return this.stats.aufrufe_nw + this.stats.aufrufe_nm;
      },
      aufrufe_oeffner() {
         return this.stats.aufrufe_ow  + this.stats.aufrufe_om;
      },


   },
   mounted() {
      var vm = this;
      const a = axios.get( this.$_APOLLO_API_URL + '/muttertag/stats?p='+this.kennwort, {
               headers: {}
            })
            .then((response) => {
                  vm.stats = response.data.stats;
                  vm.error = response.data.error;
                  vm.loading = false
            }).catch(err =>{
               vm.error = err;
               vm.loading = false; 
        });
   },
   created: function () {

   }
};
</script>

<style lang="scss">
#statistik {
 font-family: 'Orgon-Medium', Helvetica, Arial, sans-serif !important;
  font-size: 16px;
  font-weight: normal;
}

#logo {
   width: 100%;

   .container {
      display: flex;
      justify-content: left;
      padding: 20px;

      img {
         width: 100%;
         height: 100%;
         max-width: 300px;
      }
   }
}

#header {
   width: 100%;
   margin-bottom: 0px;
   
   .container {
      padding: 20px;

      .col-100 {
         display: flex;
         width: 100%; 
         text-align:left; 
         flex-wrap: wrap; 
         justify-content: space-between; 
         padding: 10px;
 /*         :nth-child(odd) {
  background-color: #d2d2d2;
}
        :nth-child(even) {
  background-color: #9a9a9a;
} */

input[type="text"],
input[type="password"] {
   height: 50px;
   border: 2px solid #ccc;
              max-width: 550px;
            width: 100%;
            margin: 20px auto;
            padding: 10px;
            border: 3px solid #ccc;
            border-radius: 1.4em;
            text-align: center;
            text-decoration: none;
            font-size: 1.5em;
            font-weight: normal;
}

input:focus {
/*    outline: 0;
   border: 2px solid $apollo-blue; */
}

::placeholder {
   color: #ccc;
 }
 
.col-50 {
   width: 40%;
   }
         .spaltenname {
            width: 30%;
         }

         .wert {
            width: 70%;
         }
      }
   }
}

.code {
   width: 50%;
}
.benutzt {
   width: 50%;
}

#stats {

   .container {
      padding: 10px;
      font-size: 20px;
      line-height: 24px;

      h3 {
         margin: 40px 0px 5px 10px;
         font-size: 1.2em;
         text-transform: uppercase;
      } 
      

      .col-100 {

         display: flex;
         width: 100%; 
         text-align:left; 
         flex-wrap: wrap; 
         justify-content: left; 
         padding: 10px;

        &.gesamt {
           font-weight:bold;
           text-transform: uppercase;
           background-color: #666 !important;
           color: #eee;
        }

         &:nth-child(odd) {
            background-color: #f2f2f2;
         }

         &:nth-child(even) {
            background-color: #f2f2f2;
         } 

         .spaltenname {
            width: 300px;
         }

         .postkarte_name {
            width: 40%;
         }
         .postkarte_wert {
            width: 15%;
            text-align: center;
            display: flex;
            align-items: flex-end;
            justify-content: center;
         }

         .aufrufe_name {
            width: 40%;
         }
         .aufrufe_wert {
            width: 20%;
            text-align: center;
            display: flex;
            align-items: flex-end;
            justify-content: center;
         }

      }
   }

}


         .btn {
            max-width: 550px;
            width: 100%;
            margin: 20px auto;
            padding: 10px;
            border: none;
            border-radius: 1.4em;
            background: $apollo-orange;
            text-align: center;
            text-decoration: none;
            font-size: 1.5em;
            font-weight: normal;
            color: white;
            cursor: pointer;
            position: relative;

            &:hover {
               box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
               background: lighten($apollo-orange, 5%);
               transform: scale(1.05);
            }

            &:active {
               background: darken($apollo-orange, 5%);
            }

            &.reload  {
               background: darken($apollo-orange, 55%);
            }
            
         }

 
@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -50px;
  border-radius: 50%;
  border-top: 10px solid rgb(255, 255, 255);
  border-right: 10px solid transparent;
  animation: spinner 1s linear infinite;
}



</style>